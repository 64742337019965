import { type ClientEnv } from '../../app/env.both';
import { booleanify } from '../utils/common';
import { getEnsure } from './ensure';

export type MarketingConfig = ReturnType<typeof marketing>;

export function marketing(env: ClientEnv) {
  const ensure = getEnsure(env);
  return {
    registrationMarketingTrackingEnabled: booleanify(
      ensure('REGISTRATION_MARKETING_TRACKING_ENABLED', 'false')
    ),
  };
}
